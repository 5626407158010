import React from "react";

interface LinkListProps {
  links: { label: string; url: string }[];
  onClose: () => void;
}

const LinkList: React.FC<LinkListProps> = ({ links, onClose }) => {
  return (
    <div className="p-4 bg-black shadow-lg rounded-md max-w-sm mx-auto">
      <button onClick={onClose} className="mb-4 text-gray-200">
        Close
      </button>
      <hr className="mb-4" />
      <ul className="space-y-2">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.url} className="text-blue-200 hover:underline">
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinkList;
