import React from "react";
import { GameState, GuessHistory } from "./constants";

interface HistoryGridProps {
  guessHistory: GuessHistory;
  tryThisPuzzle: () => void;
}

const HistoryGrid: React.FC<HistoryGridProps> = ({
  guessHistory,
  tryThisPuzzle,
}) => {
  const categoryColors: { [key: string]: string } = {
    s: "bg-yellow-600",
    e: "bg-green-700",
    m: "bg-blue-700",
    h: "bg-purple-700",
  };

  const getColor = (key: string): string => {
    return categoryColors[key] ?? "bg-gray-700";
  };

  const isShared = () => {
    return (
      guessHistory.state === GameState.SHAREDWIN ||
      guessHistory.state === GameState.SHAREDLOSS
    );
  };

  const gridData: string[][] = guessHistory.hist.map((h) => {
    const encodedArr = h.split(",");
    const decodedArr = [];
    for (let encodeIndex = 0; encodeIndex < encodedArr.length; encodeIndex++) {
      const categories = encodedArr[encodeIndex];
      for (let category = 0; category < categories.length; category++) {
        for (let timesToAdd = 0; timesToAdd <= encodeIndex; timesToAdd++) {
          decodedArr.push(categories[category]);
        }
      }
    }
    return decodedArr.reverse();
  });

  return (
    <div className="w-1/2 max-w-80 mt-4">
      <div className="text-center">Puzzle results:</div>
      {gridData.map((row: string[], rowIndex: number) => (
        <div key={`row-${rowIndex}`}>
          <div className="flex items-center flex-row">
            {row.map((cell: string, cellIndex: number) => (
              <div
                className={`grow text-center aspect-square border rounded-md m-2 ${getColor(
                  cell
                )}`}
                key={`cell-${rowIndex}-${cellIndex}`}
              ></div>
            ))}
          </div>
        </div>
      ))}
      {guessHistory.state === GameState.SHAREDWIN && (
        <div className="text-center">Won in {gridData.length} guesses.</div>
      )}
      {guessHistory.state === GameState.SHAREDLOSS && (
        <div className="text-center">Lost in {gridData.length} guesses.</div>
      )}
      {isShared() && (
        <div className="text-center m-4">
          <button
            className="px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
            onClick={tryThisPuzzle}
          >
            Try this puzzle
          </button>
        </div>
      )}
      {!isShared() && (
        <div className="text-center m-4">
          <button
            className="px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
            onClick={tryThisPuzzle}
          >
            Try again
          </button>
        </div>
      )}
    </div>
  );
};

export default HistoryGrid;
