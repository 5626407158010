import React, { useState, useEffect } from "react";
import WordGrid from "./WordGrid";
import WordInputForm from "./WordInputForm";
import HistoryGrid from "./HistoryGrid";
import URLHandler, {
  encodeGameData,
  decodeGameData,
  validateWordData,
} from "./URLHandler";
import {
  CategoryGuessCount,
  GameState,
  GuessHistory,
  PUZZLE_LINKS,
  SaveFile,
  WordData,
} from "./constants";
import GuessBar from "./GuessBar";
import LinkList from "./LinkList";

const MAX_HIGHLIGHTED = 4;

// Fisher-Yates Shuffle
function shuffle(words: string[]) {
  for (let i = words.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [words[i], words[j]] = [words[j], words[i]];
  }
  return words;
}

// return a deep copy of the word data, sorted and formatted.
function copyWordData(wordData: WordData): WordData {
  return {
    simple: {
      hint: wordData.simple.hint,
      words: [...wordData.simple.words.sort()],
    },
    easy: {
      hint: wordData.easy.hint,
      words: [...wordData.easy.words.sort()],
    },
    medium: {
      hint: wordData.medium.hint,
      words: [...wordData.medium.words.sort()],
    },
    hard: {
      hint: wordData.hard.hint,
      words: [...wordData.hard.words.sort()],
    },
  };
}

function generateSaveFile(
  key: string,
  guesses: number = 4,
  guessedLevels: string[] = [],
  guessQuads: string[][],
  guessHistory: GuessHistory,
  playTime = `${Date.now()}`
) {
  const newSaveFile = JSON.stringify({
    guesses,
    guessedLevels,
    guessQuads,
    guessHistory,
    playTime,
  });
  console.log(newSaveFile);
  localStorage.setItem(`association.lol-${key}`, newSaveFile);
}

function loadSaveFile(key: string): SaveFile {
  deleteOldAssociations();
  try {
    const save: SaveFile = JSON.parse(
      localStorage.getItem(`association.lol-${key}`) || "{}"
    );
    if (typeof save.guesses === "number" && Array.isArray(save.guessedLevels)) {
      return {
        guesses: Math.max(Math.min(save.guesses, 4), 0),
        guessQuads: save.guessQuads,
        guessedLevels: save.guessedLevels.filter((level) => {
          return ["simple", "easy", "medium", "hard"].indexOf(level) >= 0;
        }),
        guessHistory: save.guessHistory || { hist: [] },
        playTime: save.playTime || `${Date.now()}`,
      };
    }
  } catch (err) {}
  return {
    guesses: 4,
    guessedLevels: [],
    guessQuads: [],
    guessHistory: { hist: [] },
    playTime: `${Date.now()}`,
  };
}

function filterObject(obj: Record<string, any>, keys: string[]) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key))
  );
}

function removeStringsOnce(
  largerArray: string[],
  stringsToRemove: string[]
): string[] {
  const result = [...largerArray]; // Create a copy of the larger array

  stringsToRemove.forEach((str) => {
    const index = result.indexOf(str); // Find the index of the string in the result array
    if (index !== -1) {
      result.splice(index, 1); // Remove the string if it exists
    }
  });

  return result;
}

function countStringMatches(
  largerArray: string[],
  smallerArray: string[]
): number {
  // Create sets to remove duplicates
  const largerSet = new Set(largerArray);
  const smallerSet = new Set(smallerArray);

  // Count matches
  let matchCount = 0;
  smallerSet.forEach((str) => {
    if (largerSet.has(str)) {
      matchCount++;
    }
  });

  return matchCount;
}

function countCategoryMatches(wordData: WordData, guesses: string[]): string[] {
  const guessCount: CategoryGuessCount = {
    "4": "",
    "3": "",
    "2": "",
    "1": "",
  };
  const guessArray: string[] = ["", "", "", ""];
  const simpleNum = countStringMatches(guesses, wordData?.simple?.words || []);
  const easyNum = countStringMatches(guesses, wordData?.easy?.words || []);
  const mediumNum = countStringMatches(guesses, wordData?.medium?.words || []);
  const hardNum = countStringMatches(guesses, wordData?.hard?.words || []);
  if (simpleNum > 0) {
    guessArray[simpleNum - 1] += "s";
  }
  if (easyNum > 0) {
    guessArray[easyNum - 1] += "e";
  }
  if (mediumNum > 0) {
    guessArray[mediumNum - 1] += "m";
  }
  if (hardNum > 0) {
    guessArray[hardNum - 1] += "h";
  }
  return guessArray;
}

function deleteOldAssociations() {
  const now = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  // Iterate through all keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Check if the key starts with "association.lol-"
    if (key && key.startsWith("association.lol-")) {
      try {
        // Parse the JSON object stored under this key
        const storedData = JSON.parse(localStorage.getItem(key) || "{}");

        // Ensure the playTime property exists and is a valid timestamp
        if (storedData && storedData.playTime) {
          const playTimeDate = new Date(parseInt(storedData.playTime));

          // If playTime is older than one month, delete the item
          if (playTimeDate < oneMonthAgo || isNaN(playTimeDate.valueOf())) {
            localStorage.removeItem(key);
          }
        }
      } catch (e) {
        console.error(`Error processing key ${key}:`, e);
      }
    }
  }
}

const App: React.FC = () => {
  const [guesses, setGuesses] = useState<number>(4);
  const [subtitleMessage, setSubtitleMessage] = useState<string>("");
  const [subtitleWiggle, setSubtitleWiggle] = useState<boolean>(false);
  const [wordData, setWordData] = useState<WordData>({});
  const [wordDataPristine, setWordDataPristine] = useState<WordData>({});
  const [saveFileKey, setSaveFileKey] = useState<string>("");
  const [guessedCategories, setGuessedCategories] = useState<WordData>({});
  const [gameState, setGameState] = useState<GameState>(GameState.LOADING);
  const [highlighted, setHighlighted] = useState<boolean[]>([]);
  const [shuffledWords, setShuffledWords] = useState<string[]>([]);
  const [playTime, setPlayTime] = useState<string>(`${Date.now()}`);
  const [guessQuads, setGuessQuads] = useState<string[][]>([]);
  const [subTitleTimeout, setSubTitleTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);
  const [showLinks, setShowLinks] = useState(false);
  const [guessHistory, setGuessHistory] = useState<GuessHistory>({
    hist: [],
  });

  useEffect(() => {
    // Attempt to load word data from the URL when the component is mounted
    const params = new URLSearchParams(window.location.search);
    const encodedData = params.get("wordData");

    if (gameState === GameState.LOADING && encodedData) {
      decodeGameData(encodedData).then((decodedData) => {
        let maybeWordData: WordData | undefined;
        let maybeGuessHistory: GuessHistory | undefined;

        if (decodedData) {
          if ("wordData" in decodedData) {
            maybeWordData = decodedData.wordData as WordData;
            if ("guessHistory" in decodedData) {
              maybeGuessHistory = decodedData.guessHistory as GuessHistory;
            }
          } else {
            maybeWordData = decodedData;
          }
        }

        if (validateWordData(maybeWordData)) {
          resetWordGrid(maybeWordData as WordData, maybeGuessHistory);
        } else {
          setGameState(GameState.CREATING);
        }
      });
    } else if (gameState === GameState.LOADING && !encodedData) {
      setGameState(GameState.CREATING);
    }
  });

  const toggleHighlight = (index: number) => {
    const highlightedCount = highlighted.filter((h) => h).length;
    if (!highlighted[index] && highlightedCount >= MAX_HIGHLIGHTED) {
      return;
    }

    const newHighlighted = [...highlighted];
    newHighlighted[index] = !newHighlighted[index];
    setHighlighted(newHighlighted);
    if (newHighlighted[index]) {
      displaySubtitleMessage(shuffledWords[index].toUpperCase(), false);
    }
  };

  const shuffleWords = () => {
    const shuffled = shuffle([...shuffledWords]);
    setShuffledWords(shuffled);
    setHighlighted(Array(shuffled.length).fill(false));
  };

  const deselectAll = () => {
    setHighlighted(Array(shuffledWords.length).fill(false));
  };

  const displaySubtitleMessage = (message: string, wiggle: boolean) => {
    clearTimeout(subTitleTimeout);
    setSubtitleWiggle(wiggle);
    setSubtitleMessage(message);
    setSubTitleTimeout(
      setTimeout(() => {
        setSubtitleWiggle(false);
        setSubtitleMessage("");
      }, 5000)
    );
  };

  const guessHighlighted = () => {
    const selectedWords = shuffledWords
      .filter((_, index) => highlighted[index])
      .sort();

    // Check if this combo has already been guessed.
    for (var quad of guessQuads) {
      if (
        selectedWords.every((elm, i) => {
          return elm === quad[i];
        })
      ) {
        displaySubtitleMessage("Already guessed!", true);
        return;
      }
    }

    let correctCategory: string | undefined = undefined;
    let nearlyCorrectCategory: string | undefined = undefined;
    const categories: { [key: string]: number } = {
      simple: 0,
      easy: 0,
      medium: 0,
      hard: 0,
    };

    for (const category in wordData) {
      const matches = countStringMatches(
        selectedWords,
        wordData[category].words
      );
      categories[category] = matches;

      if (matches === 4) {
        correctCategory = category;
        break;
      } else if (matches === 3) {
        nearlyCorrectCategory = category;
      }
    }
    let newGuesses = guesses;
    const newGuessedCategories = {
      ...guessedCategories,
    };
    let newGameState = gameState;

    if (correctCategory) {
      newGuessedCategories[correctCategory] = wordData[correctCategory];
      setGuessedCategories(newGuessedCategories);
      const newShuffledWords = shuffle(
        removeStringsOnce(shuffledWords, selectedWords)
      );
      setShuffledWords(newShuffledWords);
      setHighlighted([]);
      if (newShuffledWords.length === 0) {
        newGameState = GameState.WIN;
      } else {
        displaySubtitleMessage("Nice job!", true);
      }
    } else {
      newGuesses = guesses - 1;
      if (nearlyCorrectCategory) {
        displaySubtitleMessage("Almost right!", true);
      } else {
        if (newGuesses === 1) {
          displaySubtitleMessage("Last guess!", true);
        } else {
          displaySubtitleMessage("Try again!", true);
        }
      }
      if (newGuesses === 0) {
        newGameState = GameState.LOSE;
      }
    }

    const guessHistoryStr = countCategoryMatches(
      wordDataPristine,
      selectedWords
    ).join(",");
    const newGuessHistory = {
      ...guessHistory,
      state: newGameState,
      hist: [...guessHistory.hist, guessHistoryStr],
    };

    setGuesses(newGuesses);
    setGameState(newGameState);
    setGuessHistory(newGuessHistory);
    setGuessQuads([...guessQuads, selectedWords]);
    generateSaveFile(
      saveFileKey,
      newGuesses,
      Object.keys(newGuessedCategories),
      [...guessQuads, selectedWords],
      newGuessHistory,
      playTime
    );
  };

  const shareGrid = async () => {
    let redirectPage = "";
    let encodedData = "";
    let numberOfGuesses = Math.max(Math.min(guessHistory.hist.length, 8), 4);
    if (gameState === GameState.WIN) {
      redirectPage = `won${numberOfGuesses}.html`;
      encodedData = await encodeGameData(wordDataPristine, {
        ...guessHistory,
        state: GameState.SHAREDWIN,
      });
    } else if (gameState === GameState.LOSE) {
      redirectPage = `lost${numberOfGuesses}.html`;
      encodedData = await encodeGameData(wordDataPristine, {
        ...guessHistory,
        state: GameState.SHAREDLOSS,
      });
    } else {
      encodedData = await encodeGameData(wordDataPristine);
    }
    const shareUrl = `${window.location.origin}${window.location.pathname}${redirectPage}?wordData=${encodedData}`;

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        if (gameState === GameState.WIN || gameState === GameState.LOSE) {
          alert("Results link copied to the clipboard.");
        } else {
          alert("Puzzle link copied to the clipboard.");
        }
      })
      .catch((err) => {
        alert("Sorry, something went wrong.");
        console.error("Failed to copy URL:", err);
      });
  };

  const resetWordGrid = async (
    newWordData: WordData,
    newGuessHistory?: GuessHistory
  ) => {
    const pristineData = copyWordData(newWordData);

    let words = shuffle([
      ...pristineData.simple.words,
      ...pristineData.easy.words,
      ...pristineData.medium.words,
      ...pristineData.hard.words,
    ]);

    // Save a copy of the word data on new puzzle load.
    setWordDataPristine(pristineData);
    // Encode the word data as a base64 JSON string
    const encodedData = await encodeGameData(pristineData);
    setSaveFileKey(encodedData);

    if (!newGuessHistory?.state) {
      // This is a new game

      // Initialize the game with a deep copy of the word data.
      setWordData(copyWordData(newWordData));
      setGuessedCategories({});
      setGuessHistory({ hist: [] });
      setHighlighted(Array(words.length).fill(false));

      const save = loadSaveFile(encodedData);
      setGuessQuads(save.guessQuads);
      if (save.guessHistory) {
        setGuessHistory(save.guessHistory);
      }

      setGuesses(save.guesses);
      const loadedGuessedCategories = filterObject(
        pristineData,
        save.guessedLevels
      );
      setGuessedCategories(loadedGuessedCategories);
      for (const category in loadedGuessedCategories) {
        words = removeStringsOnce(
          words,
          loadedGuessedCategories[category].words
        );
      }
      setShuffledWords(words);
      setPlayTime(save.playTime);

      if (save.guessedLevels.length === 4) {
        setGameState(GameState.WIN);
      } else if (save.guesses === 0) {
        setGameState(GameState.LOSE);
      } else {
        setGameState(GameState.PLAYING);
      }
    } else {
      // This is a shared result
      setGuessHistory(newGuessHistory);
      setGameState(newGuessHistory.state);
    }

    // Update the URL with the encoded word data
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("wordData", encodedData);
    window.history.replaceState({}, "", newUrl.toString());
  };

  const handleNewPuzzle = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("wordData", "");
    window.history.replaceState({}, "", newUrl.toString());
    setShuffledWords([]);
    setHighlighted([]);
    setWordData({});
    setWordDataPristine({});
    setGameState(GameState.CREATING);
  };

  const handleTryAgain = () => {
    localStorage.removeItem(`association.lol-${saveFileKey}`);
    resetWordGrid(wordDataPristine);
    displaySubtitleMessage("Good luck!", false);
  };

  const isGuessDisabled =
    highlighted.filter((h) => h).length !== MAX_HIGHLIGHTED &&
    gameState === GameState.PLAYING;

  return (
    <div className="text-white">
      <div className="flex flex-col items-center justify-top min-h-screen bg-gray-800 p-2 pt-4">
        {showLinks && (
          <div className="absolute top-20 z-10 w-full">
            <LinkList
              links={PUZZLE_LINKS}
              onClose={() => setShowLinks(false)}
            />
          </div>
        )}
        {gameState === GameState.CREATING && (
          <WordInputForm onSubmit={resetWordGrid} />
        )}
        {(gameState === GameState.PLAYING ||
          gameState === GameState.WIN ||
          gameState === GameState.LOSE) && (
          <div>
            <div className="text-center bg-gray-800 p-2 pt-8">
              Create groups of four!
            </div>
            <WordGrid
              words={shuffledWords}
              guessedCategories={guessedCategories}
              highlighted={highlighted}
              onToggleHighlight={toggleHighlight}
            />
            {gameState === GameState.WIN && (
              <div className="flex space-x-4 justify-center mb-4 h-4">
                You win!
              </div>
            )}
            {gameState === GameState.LOSE && (
              <div className="flex space-x-4 justify-center mb-4 h-4">
                You lose!
              </div>
            )}
            {gameState === GameState.PLAYING && (
              <div
                className={`flex space-x-4 text-xl justify-center mb-4 h-4 ${
                  subtitleWiggle ? "animate-wiggle" : ""
                }`}
              >
                {subtitleMessage}
              </div>
            )}
            <div className="flex space-x-4 justify-center mb-4">
              <GuessBar guesses={guesses}></GuessBar>
            </div>
            {gameState === GameState.PLAYING && (
              <div className="flex space-x-4 justify-center">
                <button
                  onClick={shuffleWords}
                  className="px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
                >
                  Shuffle
                </button>
                <button
                  onClick={deselectAll}
                  className="px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
                >
                  Deselect
                </button>
                <button
                  onClick={guessHighlighted}
                  disabled={isGuessDisabled}
                  className={`px-4 py-2 rounded-md text-black
            ${
              isGuessDisabled
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-green-300 hover:bg-green-400"
            }`}
                >
                  Guess
                </button>
              </div>
            )}
            <div className="flex space-x-4 justify-center">
              <URLHandler onShare={shareGrid} gameState={gameState} />
              <button
                onClick={handleNewPuzzle}
                className="mt-4 px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
              >
                Create
              </button>
            </div>
            <div className="flex space-x-4 justify-center">
              <button
                onClick={() => setShowLinks(true)}
                className="mt-4 px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
              >
                Sample Puzzles
              </button>
            </div>
          </div>
        )}
        {(gameState === GameState.SHAREDWIN ||
          gameState === GameState.SHAREDLOSS ||
          gameState === GameState.WIN ||
          gameState === GameState.LOSE) && (
          <HistoryGrid
            guessHistory={guessHistory}
            tryThisPuzzle={handleTryAgain}
          />
        )}
      </div>
    </div>
  );
};

export default App;
