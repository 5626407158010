import React, { useEffect } from "react";
import {
  GameState,
  GuessHistory,
  WordData,
  WordDataPlusHistory,
} from "./constants";

interface URLHandlerProps {
  gameState: GameState;
  onShare: () => void;
}

const URLHandler: React.FC<URLHandlerProps> = ({ onShare, gameState }) => {
  const shareResults = () => {
    return gameState === GameState.WIN || gameState === GameState.LOSE;
  };

  return (
    <button
      onClick={onShare}
      className="mt-4 px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
    >
      {shareResults() ? "Share Results" : "Share Puzzle"}
    </button>
  );
};

async function compressString(str: string): Promise<Uint8Array> {
  const encoder = new TextEncoder();
  const input = encoder.encode(str);

  const stream = new CompressionStream("gzip");
  const writer = stream.writable.getWriter();

  writer.write(input);
  writer.close();

  const reader = stream.readable.getReader();
  const chunks: Uint8Array[] = []; // Explicitly typing this array as Uint8Array[]
  let done: boolean | undefined;
  let value: Uint8Array | undefined;

  while (!done) {
    const result = await reader.read();
    done = result.done;
    value = result.value;
    if (value) chunks.push(value);
  }

  // Use typed array handling
  const compressed = new Uint8Array(
    chunks.reduce(
      (acc: number[], chunk: Uint8Array) => acc.concat(Array.from(chunk)),
      []
    )
  );

  return compressed;
}

function toBase64Url(buffer: Uint8Array): string {
  let binary = String.fromCharCode(...buffer);
  let base64 = btoa(binary);
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function fromBase64Url(base64Url: string): Uint8Array {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

async function compressAndEncodeJson(jsonObj: object): Promise<string> {
  const jsonString = JSON.stringify(jsonObj);
  const compressed = await compressString(jsonString);
  return toBase64Url(compressed);
}

async function decompressAndDecodeJson(
  compressedBase64Url: string
): Promise<any> {
  const compressedData = fromBase64Url(compressedBase64Url);
  const jsonString = await decompressString(compressedData);
  return JSON.parse(jsonString);
}

async function decompressString(compressedBuffer: Uint8Array): Promise<string> {
  const stream = new DecompressionStream("gzip");
  const writer = stream.writable.getWriter();

  writer.write(compressedBuffer);
  writer.close();

  const reader = stream.readable.getReader();
  let chunks: Uint8Array[] = [];
  let done: boolean | undefined;
  let value: Uint8Array | undefined;

  while (!done) {
    const result = await reader.read();
    done = result.done;
    value = result.value;
    if (value) chunks.push(value);
  }

  const decompressed = new Uint8Array(
    chunks.reduce(
      (acc: number[], chunk: Uint8Array) => acc.concat(Array.from(chunk)),
      []
    )
  );
  const decoder = new TextDecoder();
  return decoder.decode(decompressed);
}

function wordDataToArray(wordData: WordData): string[] {
  return [
    wordData.simple.hint,
    wordData.easy.hint,
    wordData.medium.hint,
    wordData.hard.hint,
    ...wordData.simple.words,
    ...wordData.easy.words,
    ...wordData.medium.words,
    ...wordData.hard.words,
  ];
}

function arrayToWordData(arr: string[]): WordData {
  return {
    simple: { words: [arr[4], arr[5], arr[6], arr[7]], hint: arr[0] },
    easy: { words: [arr[8], arr[9], arr[10], arr[11]], hint: arr[1] },
    medium: { words: [arr[12], arr[13], arr[14], arr[15]], hint: arr[2] },
    hard: { words: [arr[16], arr[17], arr[18], arr[19]], hint: arr[3] },
  };
}

function guessHistoryToString(guessHistory: GuessHistory): string {
  const str = guessHistory.hist
    .join("-")
    .replace(/,,,/g, "3")
    .replace(/,,/g, "2")
    .replace(/semh/g, "A");
  if (guessHistory.state === GameState.SHAREDWIN) {
    return `W${str}`;
  } else if (guessHistory.state === GameState.SHAREDLOSS) {
    return `L${str}`;
  }
  return "";
}

function stringToGuessHistory(str: string): GuessHistory {
  let state: GameState;
  if (str[0] === "W") {
    state = GameState.SHAREDWIN;
  } else if (str[0] === "L") {
    state = GameState.SHAREDLOSS;
  } else {
    return { hist: [] };
  }
  return {
    state,
    hist: str
      .replace(/^[WL]/g, "")
      .replace(/A/g, "semh")
      .replace(/2/g, ",,")
      .replace(/3/g, ",,,")
      .split("-"),
  };
}

// Utility functions for encoding and decoding URL data
export const encodeGameData = async (
  wordData: WordData,
  hist?: GuessHistory
): Promise<string> => {
  try {
    const data: { w: string[]; h?: string } = {
      w: wordDataToArray(wordData),
    };
    if (hist) {
      data.h = guessHistoryToString(hist);
    }
    return await compressAndEncodeJson(data);
  } catch (err) {
    console.error("Unknown compressing amd encoding data.", err);
    return Promise.resolve("");
  }
};

export const decodeGameData = async (
  encodedGameData: string
): Promise<WordDataPlusHistory | WordData | null> => {
  try {
    const decoded = await decompressAndDecodeJson(encodedGameData);
    if ("w" in decoded) {
      if ("h" in decoded) {
        return {
          wordData: arrayToWordData(decoded.w),
          guessHistory: stringToGuessHistory(decoded.h),
        } as WordDataPlusHistory;
      }
      return arrayToWordData(decoded.w) as WordData;
    }
    return arrayToWordData(decoded);
  } catch (err) {
    console.error("Decompress and decode failed, trying base64:", err);
  }

  try {
    return Promise.resolve(
      JSON.parse(decodeURIComponent(atob(encodedGameData)))
    );
  } catch (e) {
    console.error("Failed to decode data:", e);
    return Promise.resolve(null);
  }
};

export const validateWordData = (data: any): boolean => {
  if (
    data?.simple?.words?.length == 4 &&
    data?.simple?.hint &&
    data?.easy?.words?.length == 4 &&
    data?.easy?.hint &&
    data?.medium?.words?.length == 4 &&
    data?.medium?.hint &&
    data?.hard?.words?.length == 4 &&
    data?.hard?.hint
  ) {
    return true;
  }
  return true;
};

export default URLHandler;
