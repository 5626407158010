import React from "react";
import { MAX_GUESSES } from "./constants";

interface GuessBarProps {
  guesses: number;
}

const GuessBar: React.FC<GuessBarProps> = ({ guesses }) => {
  if (guesses < 0) {
    guesses = 0;
  }
  const guessesUsed = MAX_GUESSES - guesses;

  const guessStr = new Array(guesses).fill("★");
  const guessesUsedStr = new Array(guessesUsed).fill("☆");

  return (
    <div className="text-2xl">
      Guesses:
      <span>{guessStr}</span>
      <span>{guessesUsedStr}</span>
    </div>
  );
};

export default GuessBar;
