import React from "react";
import { Category, WordData } from "./constants";

interface WordGridProps {
  words: string[];
  guessedCategories: WordData;
  highlighted: boolean[];
  onToggleHighlight: (index: number) => void;
}

const WordGrid: React.FC<WordGridProps> = ({
  words,
  guessedCategories,
  highlighted,
  onToggleHighlight,
}) => {
  const getFontSize = (word: string) => {
    if (word.length <= 4) return "1.5rem";
    if (word.length <= 6) return "1rem";
    if (word.length <= 8) return "0.9rem";
    if (word.length <= 12) return "0.8rem";
    return "0.7rem";
  };

  const categoryColors: { [key: string]: string } = {
    simple: "bg-yellow-600",
    easy: "bg-green-700",
    medium: "bg-blue-700",
    hard: "bg-purple-700",
  };

  const getColor = (key: string): string => {
    return categoryColors[key] ?? "bg-gray-700";
  };

  return (
    <div className="uppercase">
      {Object.keys(guessedCategories).map((key: string) => (
        <div
          className="grid grid-cols-4 gap-2 w-full max-w-md mb-4 relative"
          key={guessedCategories[key].hint}
        >
          <div
            className={`col-span-4 border rounded-md overflow-hidden text-center font-medium break-words px-1 ${getColor(
              key
            )} text-white p-4`}
          >
            <div>{guessedCategories[key].hint}</div>
            <div>{guessedCategories[key].words.join(", ")}</div>
          </div>
        </div>
      ))}
      <div className="grid grid-cols-4 gap-2 w-full max-w-md mb-4 relative">
        {words.map((word, index) => (
          <div
            key={index}
            className={`flex items-center justify-center aspect-square border rounded-md cursor-pointer overflow-hidden
            ${
              highlighted[index]
                ? "bg-blue-300 text-black"
                : "bg-black text-white"
            }
          `}
            onClick={() => onToggleHighlight(index)}
          >
            <span
              className="text-center font-medium break-words px-1"
              style={{ fontSize: getFontSize(word) }}
            >
              {word}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WordGrid;
