import React, { useEffect, useState } from "react";
import { PUZZLE_LINKS, WordData } from "./constants";
import LinkList from "./LinkList";

interface WordInputFormProps {
  onSubmit: (wordData: WordData) => void;
}

const WordInputForm: React.FC<WordInputFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    simple: { words: ["", "", "", ""], hint: "" },
    easy: { words: ["", "", "", ""], hint: "" },
    medium: { words: ["", "", "", ""], hint: "" },
    hard: { words: ["", "", "", ""], hint: "" },
  } as WordData);
  const [isLocal, setIsLocal] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    setIsLocal(window.location.hostname === "localhost");
  });

  const handleChange = (difficulty: string, index: number, value: string) => {
    const updatedData = { ...formData };
    updatedData[difficulty].words[index] = value;
    setFormData(updatedData);
  };

  const handleHintChange = (difficulty: string, value: string) => {
    const updatedData = { ...formData };
    updatedData[difficulty].hint = value;
    setFormData(updatedData);
  };

  const handleSubmit = (e: React.FormEvent) => {
    const words = new Set([
      ...formData.simple.words,
      ...formData.easy.words,
      ...formData.medium.words,
      ...formData.hard.words,
    ]);
    e.preventDefault();
    if (words.size === 16) {
      onSubmit(formData);
    } else {
      window.alert("You must enter 16 unique words to create a puzzle.");
    }
  };

  const handleDebugFormData = () => {
    // setFormData({
    //   simple: {
    //     words: ["top", "bottom", "left", "right"],
    //     hint: "Relative positions",
    //   },
    //   easy: {
    //     words: ["lettuce", "cheese", "ketchup", "mayo"],
    //     hint: "Hamburger toppings",
    //   },
    //   medium: {
    //     words: ["guffaw", "chuckle", "giggle", "snigger"],
    //     hint: "Laugh types",
    //   },
    //   hard: {
    //     words: ["nideb", "noxin", "amabo", "hsub"],
    //     hint: "Backwards presidents",
    //   },
    // });
    setFormData({
      simple: { words: ["👘", "🩳", "👙", "🧤"], hint: "Clothing" },
      easy: { words: ["🪒", "🔪", "⛸️", "✂️"], hint: "Bladed" },
      medium: { words: ["🍆", "🍑", "🍒", "💦"], hint: "Suggestive" },
      hard: { words: ["🌫️", "🪵", "🐸", "🐕"], hint: "Rhyming" },
    });
  };

  return (
    <div>
      {showLinks && (
        <div className="absolute top-20 z-10 w-80">
          <LinkList links={PUZZLE_LINKS} onClose={() => setShowLinks(false)} />
        </div>
      )}
      <div className="flex space-x-4 justify-center mb-10">
        <button
          onClick={() => setShowLinks(true)}
          className="mt-4 px-4 py-2 bg-blue-300 text-black rounded-md hover:bg-blue-200"
        >
          Sample Puzzles
        </button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        {["simple", "easy", "medium", "hard"].map((difficulty) => (
          <div key={difficulty} className="space-y-4">
            <h2 className="text-lg text-white font-semibold capitalize">
              {difficulty} matches
            </h2>
            <input
              type="text"
              placeholder="Hint"
              maxLength={40}
              value={formData[difficulty].hint}
              onChange={(e) => handleHintChange(difficulty, e.target.value)}
              className="w-full p-2 border rounded-md border-gray-300 bg-gray-800 text-white border-gray-600 focus:ring-blue-500 focus:border-blue-500"
            />
            <hr />
            <div className="space-y-2">
              {[0, 1, 2, 3].map((index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={20}
                  placeholder={`Word ${index + 1}`}
                  value={formData[difficulty].words[index]}
                  onChange={(e) =>
                    handleChange(difficulty, index, e.target.value)
                  }
                  className="w-full p-2 border rounded-md border-gray-300 bg-gray-800 text-white border-gray-600 focus:ring-blue-500 focus:border-blue-500"
                />
              ))}
            </div>
          </div>
        ))}
        <div className="flex space-x-4 justify-center">
          <button
            type="submit"
            className="px-4 py-2 bg-green-300 text-black rounded-md hover:bg-green-400"
          >
            Create Puzzle
          </button>

          {isLocal && (
            <button
              onClick={handleDebugFormData}
              className="px-4 py-2 bg-red-300 text-black rounded-md hover:bg-red-400"
            >
              Use Debug Puzzle
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default WordInputForm;
