export interface WordData {
  [key: string]: Category;
}

export interface Category {
  words: string[];
  hint: string;
}

export interface CategoryGuessCount {
  [key: string]: string;
}

export interface WordDataPlusHistory {
  wordData: WordData;
  guessHistory: GuessHistory;
}

export enum GameState {
  LOADING,
  CREATING,
  PLAYING,
  WIN,
  LOSE,
  SHAREDWIN,
  SHAREDLOSS,
}

export interface SaveFile {
  guesses: number;
  guessQuads: string[][];
  guessedLevels: string[];
  playTime: string;
  guessHistory: GuessHistory;
}

export interface GuessHistory {
  state?: GameState;
  hist: string[];
}

export const MAX_GUESSES = 4;

export const PUZZLE_LINKS = [
  {
    label: "Party Time",
    url: "/?wordData=H4sIAAAAAAAAEz2PzQrCQAyEX2WZ8z6FCl5FCx7EQ2yju7hNSjZF-vay9ecWhvk-Jhd0anMNNdHEQ_CU5VERcSDzJQzcq5FnlZbtaeQaFp1DTxKmQgsiupX4p55MX4jYJmbL2rCdyuyIOGZ5IOJsTJ4QsaFS9GPekAhbw1Tu7J4RcXJjGtnqaiOjgddhWW5USrv0uTIdF56SCn-d36W_J1qDxG0ecX0D1CKSA-4AAAA",
  },
  // {
  //   label: "Emoji Puzzle",
  //   url: "/?wordData=H4sIAAAAAAAAE4tWcs7JL8nIzEtX0lFyyklMSU1R0lEKLk1PTy0uySxLVdJRCsqozIXIf5g_cQaEmgmmli8BUys3K-koPZq94_2OfhBjThOE8WH-lFVgatUkMNXbBqEmQiiI4KRlYKpnNUzPhKkQagdE61alWACkLtgvowAAAA",
  // },
  {
    label: "Abbreviated",
    url: "/?wordData=H4sIAAAAAAAAEy2MwQrCMBBEfyXsOX-hWASrUg8q4mGbLBLc7JaksfTvZcXTDO8N84AOSyRxE6PMFTxsMFNBFzRPKIkMXYnZvUUXcTiOhT4J56RiaodZW3U9clDORvYlWRwSr-ChTzKDh0Er_b5FxSSlgODhxGuems0HiuDhiNXwOdlowIgFPFxCG412jHEhZnsNW_3XG3i4a5MXPL_duoZCzAAAAA",
  },
  {
    label: "Breakdowns",
    url: "/?wordData=H4sIAAAAAAAAEx2NwQrCQAxEf0XmHPoRVaqCoHgVD2kb6mJ3U7Kbg38v6WmGYebNC0OqHxCupTbzLKVVEAZOq5uA0Jvw98Bd10W8qpdZDISzqW-7e4hNQXjyD4STed6b3mJ-USsg3G3h0F7zGOK1gXA03r-HVbfo8pgivskSWNOcajCeMqnNeP8BIJwzjqwAAAA",
  },
  {
    label: "Lots",
    url: "/?wordData=H4sIAAAAAAAAE32OzQrCMAzHX6Xk3KcQUREUYd7EQ-2yNdAmI20d7OklewAvSeD3_8gLzip9qU4mF5hKyBU83IXrgpEmio57-aAab4l4Njz8Y8Rzz0Fpw9FNoUivrsiX0DVqGU1y6zqigoeH0ojmiUkk27EGLeDhEFpM4OGUaU4NPFyk2hrQ5lE2ZPD2ed0rm7DFPJXEzKTjTrFkMt01rEZXYnj_ACDRvXTwAAAA",
  },
  {
    label: "Easy Street",
    url: "/?wordData=H4sIAAAAAAAAEx2NwQrCMBBEfyXMeX9CPBREoWjBgxQJca3BNFu2iUW_XjZzGRh4b24YlNmV78IrCGcOoo-YJ8cfzsWmXXZ-lpqLk6crcWYQLsWru1tA6OoMQu9TK_bhBcI1piSb4SEYDMJBqmafQDjKBMIp5lra616qrs27cErNr1yaZ2A1bed_MRvUy8Zqu_LbbryuGP9aH7wkxQAAAA",
  },
  {
    label: "2 Letters",
    url: "/?wordData=H4sIAAAAAAAAEx2NwQrCMBBEfyXMeX-iFlHR2kJVUPGQ4lpz6AaSaH5fxsvCvDe7e8dYg80uF180Q9CbuhrT03nLVRNRM01Jv8GXEC27-HLlHWymOVsof7Koz5-ki1px3LhBcGw5LhAMDQTbHWPPF3sIrmTrEy17A8XI2HYs88BhBUG3weMH8Jwab6UAAAA",
  },
];
